import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout/Layout";
// Eventhough not used directly (as a Gatsby module), this will overwrite any global styles
import careersStyles from "./styles/careers-page.scss";
import mind_map from "../img/layout/programming_code_1.svg";
import watering_plant from "../img/layout/watering_plant.svg";

import Navigation from "../components/Layout/Navigation";
import { Helmet } from "react-helmet";

export const CareersPageTemplate = ({
  title,
  description,
  introduction,
  benefits,
  openings,
}) => {
  return (
    <div className="careers-page">
      <div className="intro">
        <Navigation />

        <div className="container">
          <div className="row flex-md-row flex-sm-column hero-section">
            <div className="col-md-12">
              <h1>{title}</h1>
            </div>
            <div className="col-md-12">
              <Link to="/careers#openings" className="btn learn-more-button">
                  Open positions
              </Link>
            </div>
          </div>

          <div className="row flex-md-row flex-sm-column introduction-section">
            <div className="col-md-6">
              <h2>Working at NEOGOV Croatia</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: introduction,
                }}
              />
            </div>

            <div className="col-md-6">
              <img src={mind_map} alt="Developing code" />
            </div>
          </div>

          <div className="row flex-md-row flex-sm-column benefits-section">
            <div className="col-md-6">
              <img src={watering_plant} alt="Developing code" />
            </div>

            <div className="col-md-6">
              <h2>Benefits</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: benefits,
                }}
              />
              </div>
            </div>
        </div>
      </div>

      <div id="openings" className="openings-section">
        <div className="container title">
            <h2>Open positions</h2>
        </div >
                       
          <div id="talentlyftWidget" className="talentlyft-container talentlyft"></div>
          <Helmet> 
            <script type="text/javascript" charset="utf-8">
              {`
                window.tlOptions = {
                    elementId: 'talentlyftWidget',
                    companyId: '345fdb4c-b758-4696-a08f-734bb05ddd6f',
                    language: 'en',
                    fontFamily: 'Open Sans',
                    status: ["Published"],
                    departments: [],
                    locations: [],
                    tags: [],
                    sort: 'az', // Available options: 'az', 'za', 'modified_date', 'published_date', 'status'
                    pageSize: 10, // Max: 200
                    fontSize: '1rem',
                    color: '#211e65',
                    applicationFormOn: 'widget', // Available options: 'widget', 'careerSite'
                    fontUrl: 'https://fonts.googleapis.com/css?family=Open+Sans'            
                };
                
                var aws = document.createElement('script');
                aws.onload = function() { talentlyftWidget.render(window.tlOptions); };
                aws.src = 'https://widget.talentlyft.com';
                window.onload = () => document.body.appendChild(aws);
                document.body?.appendChild(aws);
              `}
            </script> 
          </Helmet>
      </div>
    </div>
  );
};

const CareersPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter, html },
  } = data;

  return (
    <Layout
      pageTitle={frontmatter.title}
      pageDescription={frontmatter.subTitle}
    >
      <CareersPageTemplate
        title={frontmatter.title}
        description={html}
        openings={frontmatter.openings || []}
        introduction={frontmatter.introduction}
        benefits={frontmatter.benefits}
      />
    </Layout>
  );
};

CareersPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CareersPage;

export const CareersPageQuery = graphql`
  query CareersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "careers-page" } }) {
      html
      frontmatter {
        title
        subTitle
        introduction
        benefits
      }
    }
  }
`;
